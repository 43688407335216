/*
 * @Author: 庄志莹
 * @Date: 2021-01-11 09:29:40
 * @LastEditTime: 2021-03-16 17:59:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\oss\client.js
 */
const OSS = require('ali-oss');
 
export default function Client(data) {
    //后端提供数据
    return new OSS({
        region: data.region,  //oss-cn-beijing-internal.aliyuncs.com
        accessKeyId: data.accessKeyId,
        accessKeySecret: data.accessKeySecret,
        stsToken: data.stsToken,
        bucket: data.bucket
    })
}